import React, { FC, ReactNode, useState } from "react";
import styled, { css } from "styled-components";
import Headline from "../../atoms/Headline/Headline";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Navigation, Pagination } from "swiper";
import { ChildImageSharp } from "../../../types/types";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { arrowLeftIcon, arrowRightIcon } from "../../../assets/icons";
import { Icon } from "@iconify/react";
import Lightbox from "../../atoms/Lightbox/Lightbox";
import { filterLightboxImages } from "../../../utils/filterLightboxImages";

SwiperCore.use([Pagination, Navigation]);

const StyledWrapper = styled.section`
  margin: 180px auto 0 auto;
  max-width: 1920px;

  header {
    text-align: center;

    p {
      margin: 5px 0 0;
      font-size: 2rem;

      span {
        position: relative;

        ::before {
          position: absolute;
          top: 100%;
          width: 100%;
          height: 1px;
          background: #000;
          content: "";
        }
      }
    }
  }

  @media (max-width: 1024px) {
    margin-top: 80px;

    header {
      p {
        font-size: 1.6rem;
        max-width: 80vw;
        margin: 5px auto;
      }
    }
  }

  @media (max-width: 574px) {
    margin-top: 60px;
  }
`;

const StyledImage = styled(GatsbyImage)`
  width: 70vw;
  max-width: 1357px;
  height: 645px;
  cursor: pointer;

  @media (max-width: 1024px) {
    width: 80vw;
    height: 45vw;
  }
`;

const SwiperWrapper = styled.div<{ $isManyBullets: boolean }>`
  margin-top: 60px;
  width: 100%;
  position: relative;

  @media (max-width: 1024px) {
    margin-top: 40px;
  }

  .swiper {
    max-width: 100%;
    position: relative;
    overflow: hidden;
    padding-bottom: 120px;

    @media (max-width: 1024px) {
      padding-bottom: 40px;
    }

    @media (max-width: 574px) {
      padding-bottom: 20px;
    }
  }

  .swiper-wrapper {
    display: flex;
    align-items: center;
  }

  .swiper-slide {
    opacity: 0.5;
    transition: opacity 0.5s ease-in-out;

    &-active {
      opacity: 1;
    }
  }

  .swiper-pagination {
    bottom: 0;
    position: absolute;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;

    .swiper-pagination-bullet {
      padding: 10px;
      margin-right: 70px;
      cursor: pointer;

      :last-of-type {
        margin-right: 0;
      }

      span {
        width: 10px;
        height: 10px;
        background: #eeeeee;
        border-radius: 50%;
        transition: background 0.5s ease-in-out;
        will-change: background;
        display: block;
      }

      &-active {
        span {
          background: #7bb933;
        }
      }
    }

    ${({ $isManyBullets }) =>
      $isManyBullets &&
      css`
        .swiper-pagination-bullet {
          margin-right: 20px;
        }
      `};

    @media (max-width: 1024px) {
      display: none;
    }
  }
`;

const NavigationButtonsWrapper = styled.div`
  @media (max-width: 1024px) {
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

const NavigationButton = styled.button`
  font-size: 4rem;
  background: transparent;
  padding: 10px;
  border: 0;
  cursor: pointer;
  color: #7bb933;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);

  :first-of-type {
    left: 10%;
  }

  :last-of-type {
    right: 10%;
  }

  :active {
    transform: translateY(-50%) scale(0.8);
  }

  @media (max-width: 1598px) {
    :first-of-type {
      left: 9%;
    }

    :last-of-type {
      right: 9%;
    }
  }

  @media (max-width: 1378px) {
    :first-of-type {
      left: 8%;
    }

    :last-of-type {
      right: 8%;
    }
  }

  @media (max-width: 1222px) {
    :first-of-type {
      left: 7%;
    }

    :last-of-type {
      right: 7%;
    }
  }

  @media (max-width: 1122px) {
    :first-of-type {
      left: 6%;
    }

    :last-of-type {
      right: 6%;
    }
  }

  @media (max-width: 1024px) {
    position: static;
    transform: none;

    :active {
      transform: scale(0.8);
    }

    :first-of-type {
      margin-right: 30%;
    }
  }

  @media (max-width: 574px) {
    font-size: 3rem;
  }
`;

const Gallery: FC<Props> = ({
  images,
  lightboxImages,
  subHeadline,
  headline,
}) => {
  const [swiper, setSwiper] = useState<SwiperCore>(null);
  const [isLightboxActive, setLightboxActive] = useState<boolean>(false);
  const [currentImage, setCurrentImage] = useState<number>(0);

  const onImageClick = (index: number) => {
    setCurrentImage(index);
    setLightboxActive(true);
  };

  const onSlideChange = (swiper: SwiperCore) => {
    setCurrentImage(swiper.realIndex);
  };

  const slideTo = (index: number) => swiper?.slideTo(index, 1000);

  const nextButtonId = "next-button";
  const prevButtonId = "prev-button";

  return (
    <StyledWrapper id="gallery">
      <header>
        <Headline data-aos="zoom-in-up">{headline}</Headline>
        <p data-aos="zoom-in-up" data-aos-delay="300">
          {subHeadline}
        </p>
      </header>

      <SwiperWrapper $isManyBullets={images.nodes.length > 6}>
        <Swiper
          updateOnWindowResize
          centeredSlides
          spaceBetween={40}
          breakpoints={{
            575: {
              spaceBetween: 60,
            },
            1025: {
              spaceBetween: 130,
            },
          }}
          slidesPerView="auto"
          pagination={{
            clickable: true,
            renderBullet: (i, className) =>
              `<div class="${className}"><span /></div>`,
          }}
          loop
          navigation={{
            nextEl: `#${nextButtonId}`,
            prevEl: `#${prevButtonId}`,
          }}
          grabCursor
          onSwiper={swiper => setSwiper(swiper)}
          onSlideChange={onSlideChange}
        >
          {images.nodes.map((image, i) => (
            <SwiperSlide key={i}>
              <StyledImage
                image={getImage(image.childImageSharp)}
                alt=""
                onClick={() => onImageClick(i)}
              />
            </SwiperSlide>
          ))}
        </Swiper>

        <NavigationButtonsWrapper>
          <NavigationButton id={prevButtonId} aria-label="Poprzednie zdjęcie">
            <Icon icon={arrowLeftIcon} />
          </NavigationButton>

          <NavigationButton id={nextButtonId} aria-label="Następne zdjęcie">
            <Icon icon={arrowRightIcon} />
          </NavigationButton>
        </NavigationButtonsWrapper>
      </SwiperWrapper>

      <Lightbox
        currentImageIndex={currentImage}
        setCurrentImageIndex={setCurrentImage}
        isOpen={isLightboxActive}
        setOpen={setLightboxActive}
        images={filterLightboxImages(lightboxImages.nodes).map(
          lightboxImage => ({
            src: lightboxImage,
            loading: "lazy",
            alt: "",
          })
        )}
        slideTo={slideTo}
      />
    </StyledWrapper>
  );
};

interface Props {
  images: { nodes: ChildImageSharp[] };
  lightboxImages: { nodes: ChildImageSharp[] };
  headline: string;
  subHeadline?: string | ReactNode;
}

export default Gallery;
