import React from "react";
import Gallery from "../../molecules/Gallery/Gallery";
import { ChildImageSharp } from "../../../types/types";
import { graphql, useStaticQuery } from "gatsby";

const MainGallery = () => {
  const {
    slider,
    lightbox,
  }: {
    slider: { nodes: ChildImageSharp[] };
    lightbox: { nodes: ChildImageSharp[] };
  } = useStaticQuery(query);
  return (
    <Gallery
      images={slider}
      lightboxImages={lightbox}
      headline="Galeria"
      subHeadline={
        <>
          Sprawdź jak możesz mieszkać na <span>Osiedlu pod miastem Kunów</span>.
        </>
      }
    />
  );
};

const query = graphql`
  {
    slider: allFile(
      filter: { name: { regex: "/hero|vis_2|vis_3|vis_4/" } }
      sort: { order: DESC, fields: name }
    ) {
      nodes {
        childImageSharp {
          gatsbyImageData(quality: 80)
        }
      }
    }

    lightbox: allFile(
      filter: { name: { regex: "/hero|vis_2|vis_3|vis_4/" } }
      sort: { order: DESC, fields: name }
    ) {
      nodes {
        childImageSharp {
          gatsbyImageData(quality: 90, formats: JPG, layout: FIXED)
        }
      }
    }
  }
`;

export default MainGallery;
