import React from "react";
import styled from "styled-components";
import Headline from "../../atoms/Headline/Headline";
import Map from "../../atoms/Map/Map";

const StyledWrapper = styled.section`
  margin-top: 180px;
  padding: 70px 0;
  background: rgba(238, 238, 238, 0.3);

  @media (max-width: 1024px) {
    margin-top: 80px;
  }

  @media (max-width: 574px) {
    margin-top: 60px;
    padding: 40px 0;
  }
`;

const StyledHeader = styled.header`
  display: flex;
  align-items: center;
  flex-direction: column;

  h2 {
    margin: 0 0 40px;
  }

  div {
    display: flex;
    justify-content: space-between;
    max-width: 1315px;
    width: 80%;
    margin: auto;

    p {
      max-width: 570px;
      text-align: justify;
      line-height: 1.6;
      margin: 0 15px 0 0;
    }
  }

  @media (max-width: 1024px) {
    div {
      flex-direction: column;
      align-items: center;

      p {
        margin: 0 0 20px;

        :last-of-type {
          margin: 0;
        }
      }
    }
  }

  @media (max-width: 574px) {
    h2 {
      margin: 0 0 20px;
    }
  }
`;

const StyledMap = styled(Map)`
  width: 80%;
  max-width: 1315px;
  margin: 60px auto 0 auto;
  height: 615px;

  @media (max-width: 1024px) {
    max-width: 90%;
    margin: 40px auto 0 auto;
    height: 55vw;
  }

  iframe {
    width: 100%;
    height: 100%;
  }
`;

const Location = () => (
  <StyledWrapper id="localization">
    <StyledHeader>
      <Headline data-aos="zoom-in-up">Lokalizacja</Headline>
      <div>
        <p data-aos="zoom-out">
          Osiedle położone jest w wyjątkowym miejscu z dala od miejskiego
          zgiełku, ale jednocześnie bardzo blisko miasta - Zgorzelca. <br />{" "}
          Kunów to niewielka wieś położona blisko granic Niemiec i Czech. <br />
          To idealne miejsce na własny wymarzony dom.
        </p>
        <p data-aos="zoom-out" data-aos-delay="300">
          Okolica jest wyjątkowo piękna, w pobliżu znajduje się wiele lasów i
          jezior, powietrze jest czyste, a ludzie są przyjaźni. <br />
          Urokliwa lokalizacja na wsi, a jednocześnie blisko do wszelkich
          udogodnień.
        </p>
      </div>
    </StyledHeader>
    <StyledMap center={{ lat: 53.423851, lng: 14.5555491 }} zoom={14} />
  </StyledWrapper>
);

export default Location;
