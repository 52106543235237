import React, { useContext, useEffect, useState } from "react";
import styled, { css } from "styled-components";
import Logo from "../../atoms/Logo/Logo";
import { NAVIGATION_ITEMS } from "../../../config/navigation";
import { Link } from "gatsby";
import Context from "../../../context";
import Hamburger from "../../atoms/Hamburger/Hamburger";
import { useWindowSize, useWindowScroll } from "react-use";

const StyledWrapper = styled.nav<{ $scrolled: boolean }>`
  display: flex;
  position: absolute;
  left: 0;
  top: 70px;
  z-index: 9999999;
  width: 100%;
  padding: 0 100px;
  align-items: center;
  justify-content: space-between;

  @media (max-width: 1350px) {
    padding: 0 50px;
  }

  @media (max-width: 1024px) {
    padding: 0 10%;
    top: 0;
    position: fixed;
    z-index: 999999;
    will-change: height;
    height: 140px;
    transition: background 0.2s ease-in-out, height 0.2s ease-in-out,
      box-shadow 0.2s ease-in-out;

    ${({ $scrolled }) =>
      $scrolled &&
      css`
        background: #fff;
        height: 120px;
        box-shadow: 0 5px 20px -15px rgba(0, 0, 0, 0.2);
      `}
  }

  @media (max-width: 574px) {
    height: 120px;

    ${({ $scrolled }) =>
      $scrolled &&
      css`
        height: 80px;
      `}
  }
`;

const StyledLogo = styled(Logo)<{ $scrolled: boolean }>`
  font-size: 12rem;
  color: #fff;

  @media (max-width: 574px) {
    width: 8.5rem;
  }

  ${({ $scrolled }) =>
    $scrolled &&
    css`
      color: #393e42;
    `}
`;

const StyledList = styled.ul<{
  $isHamburgerActive: boolean;
  $scrolled: boolean;
}>`
  display: flex;
  list-style-type: none;

  @media (max-width: 1024px) {
    width: 100%;
    position: absolute;
    padding: 40px 0;
    display: flex;
    left: 0;
    top: 100%;
    background: #fff;
    margin: 0;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    transform: scaleY(0) scaleX(0.8);
    transition: transform 0.4s ease-in-out;
    transform-origin: center top;

    ${({ $isHamburgerActive }) =>
      $isHamburgerActive &&
      css`
        transform: scaleY(1) scaleX(1);
      `}

    ${({ $scrolled }) =>
      $scrolled &&
      css`
        border-top: 1px solid #ccc;
        box-shadow: 0 5px 10px -8px rgba(0, 0, 0, 0.2);
      `}
  }
`;

const StyledItem = styled.li`
  margin-right: 75px;
  color: #fff;
  font-weight: 400;
  font-size: 2rem;

  :last-of-type {
    margin-right: 0;
  }

  a {
    color: inherit;
    text-decoration: none;

    span {
      position: relative;
      ::before {
        position: absolute;
        left: 50%;
        transform: translateX(-50%) scaleX(0);
        bottom: -4px;
        width: 110%;
        height: 1px;
        background: #fff;
        content: "";
        transition: transform 0.6s cubic-bezier(0.33, 1, 0.68, 1);
        transform-origin: left center;
      }
    }
  }

  :hover {
    a span::before {
      transform: translateX(-50%) scaleX(1);
    }
  }

  @media (max-width: 1692px) {
    margin-right: 40px;
    font-size: 1.8rem;
  }

  @media (max-width: 1350px) {
    margin-right: 30px;
    font-size: 1.6rem;
  }

  @media (max-width: 1024px) {
    color: #000;
    margin: 0 0 30px;
    font-size: 1.8rem;
    text-transform: uppercase;
    width: 100%;
    text-align: right;
    font-weight: 300;
    padding: 0 15%;

    :last-of-type {
      margin: 0;
    }

    a {
      width: 100%;
      display: block;

      span {
        ::before,
        ::after {
          background: #000;
        }
      }
    }
  }
`;

const StyledHamburger = styled(Hamburger)`
  @media (min-width: 1025px) {
    display: none;
  }
`;

const MainNavigation = () => {
  const { scroller } = useContext(Context);
  const [isHamburgerActive, setHamburgerActive] = useState<boolean>(false);
  const { y } = useWindowScroll();
  const { height } = useWindowSize();

  const scrolled = y > height - 200;

  return (
    <StyledWrapper $scrolled={scrolled}>
      <Link to="/">
        <StyledLogo $scrolled={scrolled} />
      </Link>
      <StyledHamburger
        toggled={isHamburgerActive}
        onToggle={setHamburgerActive}
        scrolled={scrolled}
      />
      <StyledList $isHamburgerActive={isHamburgerActive} $scrolled={scrolled}>
        {NAVIGATION_ITEMS.map(({ name, href, onClick }, index) => (
          <StyledItem key={index}>
            <a
              href={href}
              data-scroll={onClick ? undefined : ""}
              onClick={
                onClick
                  ? e => {
                      e.preventDefault();
                      setHamburgerActive(false);
                      onClick(scroller);
                    }
                  : () => {
                      setHamburgerActive(false);
                    }
              }
            >
              <span>{name}</span>
            </a>
          </StyledItem>
        ))}
      </StyledList>
    </StyledWrapper>
  );
};

export default MainNavigation;
