import React, { FC } from "react";
import styled, { css } from "styled-components";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { ChildImageSharp } from "../../../types/types";
import { graphql, useStaticQuery } from "gatsby";
import IconBlock from "../../atoms/IconBlock/IconBlock";
import {
  cityIcon,
  estateIcon,
  highwayIcon,
  treeIcon,
} from "../../../assets/icons";
import Headline from "../../atoms/Headline/Headline";
import { useWindowSize } from "react-use";

const StyledWrapper = styled.section`
  margin: 240px auto 0;
  width: 100%;
  max-width: 1920px;

  @media (max-width: 1024px) {
    margin-top: 80px;
  }
`;

const StyledContainer = styled.div`
  width: 100%;
  max-width: 1609px;
  display: flex;

  @media (max-width: 1024px) {
    flex-direction: column;
    width: 100%;
    padding: 0 10%;
    align-items: center;
  }
`;

const StyledGatsbyImageWrapper = styled.div<{ $second?: boolean }>`
  width: 52vw;
  max-width: 998px;
  height: 861px;

  @media (max-width: 1300px) {
    width: 40vw;
  }

  @media (max-width: 1024px) {
    width: 100%;
    height: 260px;
    margin: 40px 0;

    :first-of-type {
      display: none;
    }
  }

  ${({ $second }) =>
    $second &&
    css`
      @media (min-width: 1025px) {
        display: none;
      }
    `}
`;

const StyledGatsbyImage = styled(GatsbyImage)`
  width: 100%;
  height: 100%;
`;

const StyledInnerWrapper = styled.div`
  position: relative;
  flex: 1;
  max-width: 590px;

  @media (max-width: 1024px) {
    order: -1;
  }
`;

const StyledBox = styled.div`
  position: absolute;
  z-index: 20;
  width: 690px;
  height: 520px;
  padding: 55px;
  box-shadow: 15px 15px 40px rgba(0, 0, 0, 0.1);
  transform: translate(-100px, -60px);
  background: #fff;

  h3 {
    font-size: 4.2rem;
    font-weight: 600;
    margin: 0 0 40px 0;

    @media (max-width: 1024px) {
      text-align: center;
      font-size: 3rem;
    }

    @media (max-width: 574px) {
      font-size: 2rem;
      margin: 0 0 30px 0;
    }
  }

  p {
    margin: 0;
    font-weight: 300;
    line-height: 1.5;
    :first-of-type {
      margin-bottom: 15px;
    }
  }

  @media (max-width: 1024px) {
    box-shadow: unset;
    position: static;
    width: unset;
    height: unset;
    padding: unset;
    transform: unset;
  }
`;

const StyledIconBlocksWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  height: 100%;
  width: 100%;
  margin: auto;

  > div {
    display: flex;
    justify-content: space-between;

    :first-of-type > div {
      :nth-of-type(1),
      :nth-of-type(2) {
        margin-bottom: 20px;
      }
    }

    > div {
      :nth-of-type(odd) {
        margin-right: 20px;
      }
    }
  }

  @media (max-width: 299px) {
    > div {
      flex-direction: column;

      > div {
        margin-right: 0 !important;
        margin-bottom: 20px !important;
      }
    }
  } ;
`;

const AboutEstate: FC = () => {
  const { file }: { file: ChildImageSharp } = useStaticQuery(query);
  return (
    <StyledWrapper id="about_estate">
      <StyledContainer>
        <StyledGatsbyImageWrapper
          data-aos="fade-right"
          data-aos-duration="1000"
          data-aos-easing="ease-out-quint"
          data-aos-offset="300"
        >
          <StyledGatsbyImage
            image={getImage(file.childImageSharp)}
            alt="Osiedle pod miastem Kunów"
          />
        </StyledGatsbyImageWrapper>

        <StyledInnerWrapper>
          <StyledBox>
            <Headline data-aos="zoom-in-up">O osiedlu</Headline>
            <h3 data-aos="zoom-in-up" data-aos-delay="300">
              Nowe osiedle w dogodnej lokalizacji
            </h3>
            <p data-aos="zoom-in-up" data-aos-delay="300">
              Osiedle pod miastem to monitorowany i zamknięty kompleks
              składający się z 12 domów w zabudowie bliźniaczej. Domy znajdują
              się w spokojnej okolicy w miejscowości Kunów.
            </p>
            <p data-aos="zoom-in-up" data-aos-delay="600">
              Oddajemy państwu lokale w standardzie deweloperskim PLUS. Oznacza
              to, że każdy dom wyposażony zostanie w pełne ogrodzenie, kostkę
              brukową przed domem, oświetlenie zewnętrzne, system monitoringu,
              nowoczesną pompę ciepła oraz wiele innych.
            </p>
          </StyledBox>

          <StyledGatsbyImageWrapper $second>
            <StyledGatsbyImage
              image={getImage(file.childImageSharp)}
              alt="Osiedle pod miastem Kunów"
            />
          </StyledGatsbyImageWrapper>

          <StyledIconBlocksWrapper>
            <div>
              <div data-aos="zoom-out">
                <IconBlock icon={cityIcon}>
                  3 km od
                  <br /> Zgorzelca
                </IconBlock>
              </div>
              <div data-aos="zoom-out" data-aos-delay="300">
                <IconBlock icon={highwayIcon}>
                  9 km od autostrady
                  <br /> A4
                </IconBlock>
              </div>
            </div>

            <div>
              <div data-aos="zoom-out" data-aos-delay="600">
                <IconBlock icon={estateIcon}>
                  Zamknięte i<br /> monitorowane osiedle
                </IconBlock>
              </div>
              <div data-aos="zoom-out" data-aos-delay="900">
                <IconBlock icon={treeIcon}>
                  Bliskość lasów i<br /> tenerów zielonych
                </IconBlock>
              </div>
            </div>
          </StyledIconBlocksWrapper>
        </StyledInnerWrapper>
      </StyledContainer>
    </StyledWrapper>
  );
};

const query = graphql`
  {
    file(name: { eq: "hero" }) {
      childImageSharp {
        gatsbyImageData(quality: 80)
      }
    }
  }
`;

export default AboutEstate;
