import React, { FC } from "react";

const Text: FC<{ children: string }> = ({ children }) => (
  <span>
    {children &&
      Array.from(children).map((c, index) => (
        <span key={index}>{c === " " ? <>&nbsp;</> : c}</span>
      ))}
  </span>
);

export default Text;
