import { ChildImageSharp } from "../types/childImageSharp";
import { ImageDataLike } from "gatsby-plugin-image";

export const filterImages = (nodes: ChildImageSharp[]) =>
  nodes.map(child => child.childImageSharp);

export const filterLightboxImages = (nodes: ChildImageSharp[]) =>
  nodes.map(
    child =>
      (
        ((child.childImageSharp as any).gatsbyImageData as ImageDataLike)
          .images as any
      ).fallback.src as string
  );
