import React from "react";
import styled from "styled-components";
import Headline from "../../atoms/Headline/Headline";
import Standard from "../../atoms/Standard/Standard";
import {
  brickwallIcon,
  fenceIcon,
  fundamentIcon,
  roofIcon,
  solarPanelIcon,
  windowIcon,
} from "../../../assets/icons";
import { useWindowSize } from "react-use";

const StyledWrapper = styled.section`
  margin-top: 180px;

  @media (max-width: 1024px) {
    margin-top: 80px;
  }

  @media (max-width: 574px) {
    margin-top: 60px;
  }
`;

const StyledHeadline = styled(Headline)`
  text-align: center;

  span {
    display: block;
  }
`;

const StandardsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 90%;
  max-width: 950px;
  margin: 80px auto 0;

  > div {
    display: flex;
    justify-content: space-between;

    :last-of-type {
      margin-top: 100px;
    }
  }

  @media (max-width: 1024px) {
    > div {
      justify-content: center;
      margin-bottom: 70px;

      :last-of-type {
        margin-bottom: 0;
        margin-top: 0;
      }
    }
  }

  @media (max-width: 574px) {
    margin: 60px auto 0 auto;
    > div {
      max-width: 300px;
      width: 100%;
      justify-content: space-between;
      margin: 0 auto 30px auto;
    }
  }

  @media (max-width: 342px) {
    > div {
      flex-direction: column;
      align-items: center;
      margin-bottom: 0;
    }
  }
`;

const StyledStandard = styled(Standard)`
  @media (max-width: 1024px) {
    margin-right: 70px;
    :last-of-type {
      margin-right: 0;
    }
  }

  @media (max-width: 574px) {
    margin-right: 30px;
  }

  @media (max-width: 342px) {
    margin-bottom: 30px;
    margin-right: 0;
  }
`;

const Standards = () => {
  const { width } = useWindowSize();

  return (
    <StyledWrapper id="standards">
      <header>
        <StyledHeadline>
          <span data-aos="zoom-in-up">Standard</span>{" "}
          <span data-aos="zoom-in-up" data-aos-delay="100">
            wykończenia
          </span>
        </StyledHeadline>
      </header>

      <StandardsWrapper>
        {width >= 1025 ? (
          <>
            <div>
              <StyledStandard icon={fenceIcon} data-aos="zoom-out">
                Dla lokalu przynależny jest ogródek
              </StyledStandard>
              <StyledStandard
                icon={roofIcon}
                circleType="secondary"
                data-aos="zoom-out"
                data-aos-delay="300"
              >
                Dach wykonany z dachówki w kolorze antracytowym
              </StyledStandard>
              <StyledStandard
                icon={fundamentIcon}
                iconSize="6rem"
                data-aos="zoom-out"
                data-aos-delay="600"
              >
                Solidne fundamenty wykonane klasycznie
              </StyledStandard>
            </div>
            <div>
              <StyledStandard icon={solarPanelIcon} data-aos="zoom-out">
                Nowoczesna pompa ciepła z możliwością podłączenia fotowoltaiki
              </StyledStandard>
              <StyledStandard
                icon={brickwallIcon}
                iconSize="8rem"
                data-aos="zoom-out"
                data-aos-delay="300"
              >
                Ściany wewnętrzne wykonane z porothermu
              </StyledStandard>
              <StyledStandard
                icon={windowIcon}
                iconSize="8rem"
                data-aos="zoom-out"
                data-aos-delay="600"
              >
                Okna z pakietem trzyszybowym w kolorze antracyt
              </StyledStandard>
            </div>
          </>
        ) : (
          <>
            <div>
              <StyledStandard icon={fenceIcon}>
                Dla lokalu przynależny jest ogródek
              </StyledStandard>
              <StyledStandard
                icon={roofIcon}
                circleType="secondary"
                minIconSize="4.8rem"
              >
                Dach wykonany z dachówki w kolorze antracytowym
              </StyledStandard>
            </div>
            <div>
              <StyledStandard icon={fundamentIcon} iconSize="6rem">
                Solidne fundamenty wykonane klasycznie
              </StyledStandard>
              <StyledStandard icon={solarPanelIcon} minIconSize="5rem">
                Nowoczesna pompa ciepła z możliwością podłączenia fotowoltaiki
              </StyledStandard>
            </div>
            <div>
              <StyledStandard
                icon={brickwallIcon}
                iconSize="8rem"
                minIconSize="5.2rem"
              >
                Ściany wewnętrzne wykonane z porothermu
              </StyledStandard>
              <StyledStandard
                icon={windowIcon}
                iconSize="8rem"
                minIconSize="5.2rem"
              >
                Okna z pakietem trzyszybowym w kolorze antracyt
              </StyledStandard>
            </div>
          </>
        )}
      </StandardsWrapper>
    </StyledWrapper>
  );
};

export default Standards;
