import React, { useContext, useEffect } from "react";
import styled from "styled-components";
import { GatsbyImage, getImage, ImageDataLike } from "gatsby-plugin-image";
import { graphql, useStaticQuery } from "gatsby";
import ScrollIcon from "../../atoms/ScrollIcon/ScrollIcon";
import { ChildImageSharp } from "../../../types/types";
import Context from "../../../context";
import Text from "../../atoms/Text/Text";
import gsap from "gsap";

const StyledWrapper = styled.div`
  width: 100%;
  height: calc(var(--vh) * 100);
  min-height: 780px;
  overflow: hidden;
  position: relative;
  @media (max-width: 1024px) {
    min-height: unset;
  }
`;

const StyledImage = styled(GatsbyImage)`
  width: 100%;
  height: 100%;
`;

const StyledLinesWrapper = styled.div`
  z-index: 2;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;
  background: rgba(0, 0, 0, 0.65);
  padding: 0 18.75vw;

  span {
    width: 1px;
    height: 100%;
    background: rgba(125, 125, 125, 0.35);

    :last-of-type {
      position: absolute;
      top: 56px;
      left: 0;
      width: 100%;
      height: 1px;
    }
  }

  @media (max-width: 1024px) {
    span {
      display: none;
    }
  }
`;

const StyledTopLine = styled.span``;

const StyledInnerWrapper = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 3;
  padding: 0 5%;

  h1 {
    font-family: "Bebas Neue", cursive;
    font-size: 9.8rem;
    color: #fff;
    display: flex;
    flex-direction: column;
    align-items: center;
    letter-spacing: 2px;

    > span {
      position: relative;
      overflow: hidden;
      display: flex;
    }

    > div {
      font-family: "Roboto", sans-serif;
      font-weight: 100;
      font-size: 6.4rem;
      text-transform: uppercase;
      position: relative;
      padding: 0 80px;
      letter-spacing: 4px;

      > span {
        position: relative;
        overflow: hidden;
        display: flex;
      }

      div {
        width: 60px;
        height: 1px;
        background: #fff;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        content: "";
      }

      div:first-of-type {
        left: 0;
        transform-origin: right center;
      }

      div:last-of-type {
        right: 0;
        transform-origin: left center;
      }
    }
  }

  @media (max-width: 826px) {
    h1 {
      font-size: 6.5rem;
      text-align: center;

      > div {
        font-size: 4.4rem;
      }
    }
  }

  @media (max-width: 574px) {
    h1 {
      font-size: 4.5rem;

      > div {
        font-size: 3.4rem;
        padding: 0 40px;
        letter-spacing: 0px;

        div {
          width: 20px;
        }
      }
    }
  }
`;

const ScrollButton = styled.button`
  cursor: pointer;
  background: transparent;
  border: 0;
  outline: none;
  padding: 0 10px;
  z-index: 4;
  position: absolute;
  bottom: 32px;
  left: 50%;
  transform: translateX(-50%);
`;

const Hero = () => {
  const { file }: { file: ChildImageSharp } = useStaticQuery(query);
  const { scroller } = useContext(Context);

  useEffect(() => {
    const tl = gsap.timeline();

    tl.to("h1 > span > span, h1 > div > span > span", {
      duration: 0,
      y: "105%",
    })
      .to("h1 > div > div, h1 > div > div", {
        scaleX: 0,
        translateY: "-50%",
        duration: 0,
      })
      .to("h1 > span > span", {
        stagger: 0.03,
        duration: 2,
        y: 0,
        delay: 0.5,
        ease: "expo.out",
      })
      .to("h1 > div > span > span", {
        stagger: 0.03,
        duration: 2,
        y: 0,
        delay: -2,
        ease: "expo.out",
      })
      .to("h1 > div > div, h1 > div > div", {
        duration: 1,
        scaleX: 1,
        translateY: "-50%",
        delay: -1.5,
        ease: "expo.out",
      });
  }, []);

  return (
    <StyledWrapper>
      <StyledImage
        alt="Osiedle pod miastem Kunów"
        image={getImage(file.childImageSharp)}
      />
      <StyledLinesWrapper>
        <span />
        <span />
        <span />
        <span />
        <span />
        <span />
      </StyledLinesWrapper>
      <StyledInnerWrapper>
        <h1>
          <Text>Osiedle pod miastem</Text>
          <div>
            <div />
            <div />
            <Text>Kunów</Text>
          </div>
        </h1>
      </StyledInnerWrapper>
      <ScrollButton
        onClick={() => scroller.to("#about_estate")}
        aria-label="Przewiń w dół"
      >
        <ScrollIcon />
      </ScrollButton>
    </StyledWrapper>
  );
};

const query = graphql`
  {
    file(name: { eq: "hero" }) {
      childImageSharp {
        gatsbyImageData(quality: 80)
      }
    }
  }
`;

export default Hero;
