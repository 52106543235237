import React, { FC } from "react";
import styled from "styled-components";
import { Icon } from "@iconify/react";

const StyledWrapper = styled.div`
  display: flex;
  flex-direction: column;
  font-weight: 300;
  align-items: center;
  text-align: center;
  font-size: 1.8rem;
  width: 210px;
  line-height: 1.5;
  padding: 25px 15px;
  border-radius: 10px;
  transition: box-shadow 0.2s ease-in-out, transform 0.4s ease-in-out;
  user-select: none;

  svg {
    font-size: 6rem;
    color: #7bb933;
    margin-bottom: 18px;
  }

  :hover {
    box-shadow: 0 0 24px 0 #00000012;
    transform: translateY(-5%);
  }

  @media (max-width: 574px) {
    font-size: 1.4rem;
    padding: 0;
    width: 140px;

    :hover {
      box-shadow: none;
      transform: none;
      user-select: all;
    }

    svg {
      font-size: 4.5rem;
      margin-bottom: 12px;
    }
  }

  @media (max-width: 366px) {
    width: 130px;
  }
`;

const IconBlock: FC<{ icon: any }> = ({ icon, children, ...props }) => (
  <StyledWrapper {...props}>
    <Icon icon={icon} />
    {children}
  </StyledWrapper>
);

export default IconBlock;
