import React from "react";
import BasicTemplate from "../templates/BasicTemplate";
import Hero from "../components/molecules/Hero/Hero";
import MainNavigation from "../components/molecules/MainNavigation/MainNavigation";
import AboutEstate from "../components/molecules/AboutEstate/AboutEstate";
import InteractiveVisualizationSection from "../components/organisms/InteractiveVisualizationSection/InteractiveVisualizationSection";
import Location from "../components/molecules/Location/Location";
import Standards from "../components/molecules/Standard/Standards";
import Contact from "../components/molecules/Contact/Contact";
import MainGallery from "../components/organisms/MainGallery/MainGallery";
import InteriorGallery from "../components/organisms/InteriorGallery/InteriorGallery";

const Index = () => (
  <BasicTemplate
    header={() => (
      <>
        <MainNavigation />
        <Hero />
      </>
    )}
  >
    <AboutEstate />
    <MainGallery />
    <InteriorGallery />
    <InteractiveVisualizationSection />
    <Location />
    <Standards />
    {/*<OurInvestments />*/}
    <Contact />
  </BasicTemplate>
);

export default Index;
