import React, { FC } from "react";
import styled, { css } from "styled-components";
import { Icon } from "@iconify/react";

const StyledWrapper = styled.div`
  position: relative;
  width: 195px;
  padding-top: 48px;
  user-select: none;

  @media (max-width: 574px) {
    width: 130px;
    padding-top: 18px;
  }
`;

const StyledCircle = styled.div<{ $circleType: "primary" | "secondary" }>`
  width: 98px;
  height: 98px;
  border-radius: 50%;
  background: #eee;
  position: absolute;
  z-index: -1;
  right: 10px;
  top: 0;
  will-change: width, height;
  transition: transform 1s ease-out;

  @media (max-width: 574px) {
    width: 60px;
    height: 60px;
  }

  ${({ $circleType }) =>
    $circleType === "secondary" &&
    css`
      width: 40px;
      height: 40px;
      background: #7bb933;
      transform: scale(1) !important;

      @media (max-width: 574px) {
        width: 20px;
        height: 20px;
      }
    `};
`;

const StyledInnerWrapper = styled.div<{
  $iconSize?: string;
  $minIconSize?: string;
}>`
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;

  svg {
    font-size: ${({ $iconSize }) => $iconSize || "7rem"};

    @media (max-width: 574px) {
      font-size: ${({ $minIconSize }) => $minIconSize || "4.2rem"};
    }
  }

  > span {
    margin-top: 25px;
  }
`;

const Standard: FC<{
  icon: any;
  children: string;
  circleType?: "primary" | "secondary";
  iconSize?: string;
  minIconSize?: string;
  className?: string;
}> = ({
  children,
  icon,
  circleType = "primary",
  iconSize,
  className,
  minIconSize,
  ...props
}) => (
  <StyledWrapper className={className} {...props}>
    <StyledCircle $circleType={circleType} />
    <StyledInnerWrapper $iconSize={iconSize} $minIconSize={minIconSize}>
      <Icon icon={icon} />
      <span>{children}</span>
    </StyledInnerWrapper>
  </StyledWrapper>
);

export default Standard;
