import React, { FC } from "react";
import GoogleMapReact from "google-map-react";
import { GOOGLE_MAP_STYLE } from "../../../config/config";

const GoogleMap: FC<{
  center: { lat: number; lng: number };
  zoom: number;
  className?: string;
  bootstrapURLKeys?: { key: string };
}> = ({ className, center, zoom, bootstrapURLKeys }) => {
  return (
    <div className={className}>
      {/*<GoogleMapReact*/}
      {/*  bootstrapURLKeys={bootstrapURLKeys}*/}
      {/*  defaultZoom={zoom}*/}
      {/*  defaultCenter={center}*/}
      {/*  options={{*/}
      {/*    styles: GOOGLE_MAP_STYLE,*/}
      {/*  }}*/}
      {/*/>*/}
      <iframe src="https://maps.google.com/maps?q=51.1033017,15.0484817&hl=pl;z=14&amp;output=embed" />
    </div>
  );
};

export default GoogleMap;
