import React, { FC } from "react";
import { Squash } from "hamburger-react";
import styled from "styled-components";

const StyledWrapper = styled.div`
  div > div > div {
    height: 2px !important;
  }
`;

const Hamburger: FC<{
  toggled: boolean;
  className?: string;
  onToggle: React.Dispatch<React.SetStateAction<boolean>>;
  scrolled: boolean;
}> = ({ toggled, className, onToggle, scrolled }) => {
  return (
    <StyledWrapper className={className}>
      <Squash
        size={38}
        toggled={toggled}
        onToggle={onToggle}
        rounded
        color={scrolled ? "#000" : "#fff"}
        label="Pokaż menu"
      />
    </StyledWrapper>
  );
};

export default Hamburger;
