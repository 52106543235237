import React, { FC } from "react";
import styled, { keyframes } from "styled-components";

const dashAnimation = keyframes`
  0% {
    opacity: 1;
    transform: translateY(0);
  }
  
  100% {
    opacity: 0;
    transform: translateY(20%);
  }
`;

const arrowAnimation = keyframes`
  0% {
    opacity: 1;
    transform: translateY(0);
  }

  50% {
    opacity: 0.3;
    transform: translateY(2%);
  }
  
  100% {
    transform: translateY(0);
    opacity: 1;
  }
`;

const StyledSvg = styled.svg`
  overflow: visible;
  .scroll-dash {
    animation: ${dashAnimation} 1.5s ease infinite;
  }

  .scroll-arrow {
    animation: ${arrowAnimation} 1.5s ease-in-out infinite;
  }
`;

const ScrollIcon: FC<{ className?: string }> = ({ className }) => {
  return (
    <StyledSvg
      width="34"
      height="64"
      viewBox="0 0 34 64"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <g>
        <path
          d="M16.9735 0C11.459 0 6.97266 4.48631 6.97266 10.0009V23.9991C6.97266 29.5137 11.459 34 16.9735 34C22.4881 34 26.9744 29.5137 26.9744 23.9991V10.0009C26.9744 4.48631 22.4881 0 16.9735 0ZM24.9345 23.9991C24.9345 28.3887 21.3631 31.9601 16.9735 31.9601C12.584 31.9601 9.01257 28.3887 9.01257 23.9991V10.0009C9.01257 5.61133 12.584 2.03992 16.9735 2.03992C21.3631 2.03992 24.9345 5.61133 24.9345 10.0009V23.9991Z"
          fill="white"
        />
        <path
          d="M16.9738 6.76367C16.4104 6.76367 15.9539 7.22021 15.9539 7.78363V12.4782C15.9539 13.0414 16.4104 13.4982 16.9738 13.4982C17.537 13.4982 17.9938 13.0414 17.9938 12.4782V7.78363C17.9938 7.22021 17.5372 6.76367 16.9738 6.76367Z"
          fill="white"
          className="scroll-dash"
        />
      </g>
      <g >
        <path
          d="M28.1394 51.1492L17.4939 61.7957L6.8483 51.1492C6.6497 50.9574 6.33317 50.9629 6.14131 51.1615C5.9542 51.3553 5.9542 51.6624 6.14131 51.8561L17.1409 62.8557C17.3361 63.0509 17.6526 63.0509 17.8478 62.8557L28.8474 51.8561C29.0392 51.6575 29.0337 51.341 28.8351 51.1492C28.6413 50.962 28.3342 50.962 28.1404 51.1492L28.1394 51.1492Z"
          fill="white"
          className="scroll-arrow"
        />
        <path
          d="M28.1394 42.1497L17.4939 52.7962L6.8483 42.1497C6.6497 41.9578 6.33317 41.9634 6.14131 42.162C5.9542 42.3557 5.9542 42.6629 6.14131 42.8567L17.1409 53.8562C17.3361 54.0514 17.6526 54.0514 17.8478 53.8562L28.8474 42.8567C29.046 42.6648 29.0515 42.3483 28.8597 42.1497C28.6678 41.9511 28.3513 41.9456 28.1527 42.1374C28.1485 42.1414 28.1444 42.1455 28.1404 42.1497H28.1394Z"
          fill="white"
          className="scroll-arrow"
        />
      </g>
    </StyledSvg>
  );
};

export default ScrollIcon;
